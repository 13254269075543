<mat-form-field class="list">
    <mat-chip-list #chipList aria-label="Selection d'adresses mail">
        <mat-chip *ngFor="let addr of addrs" [selectable]="selectable" [removable]="removable" (removed)="remove(addr)">
            {{addr}}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input placeholder="Liste des adresses d'urgence" #addrInput [formControl]="addrCtrl" [matAutocomplete]="auto" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event)">
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let addr of filteredAddrs | async" [value]="addr">
            {{addr}}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>