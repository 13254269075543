
<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<form (submit)="submit()">
<h1 mat-dialog-title>Réinitialisez votre mot de passe</h1>
<div mat-dialog-content>
  <p>Veuillez saisir votre adresse mail pour obtenir un lien de réinitialisation de mot de passe.</p>
    <mat-form-field>
      <mat-label>Adresse email</mat-label>
      <input matInput type="email" required [formControl]="email">
      <mat-error>
        Veuillez saisir une adresse mail valide.
      </mat-error>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-button type="button" (click)="cancel()">Annuler</button>
    <button mat-raised-button color="primary" type="submit" [disabled]="email.invalid">Recevoir un lien</button>
  </div>
</form>