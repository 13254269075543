export type SearchResult<T> = {
  items: T[];
  total: number;
  pagination?: Pagination;
  filters?: ColumnFilter[];
  sorters?: ColumnSorter[];
};

export type SearchParams = {
  filters?: ColumnFilter[];
  sorters?: ColumnSorter[];
  pagination?: Pagination;
};

export type ColumnFilter = {
  column: string;
  matchmode: MatchMode;
  rules: Condition[];
};

export enum MatchMode {
  ALL,
  ANY,
}

export type Condition = {
  value: any;
  filterOperator: FilterOperator;
};

export enum FilterOperator {
  EQUAL,
  NOT_EQUAL,
  CONTAINS,
  LESS_THAN,
  LESS_THAN_OR_EQUALS,
  GREATER_THAN,
  GREATER_THAN_OR_EQUALS,
}

export type ColumnSorter = {
  column: string;
  mode: OrderMode;
};

export enum OrderMode {
  ASC,
  DESC,
}

export type Pagination = {
  pageNumber: number;
  pageSize: number;
};
