import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from "src/app/auth.service";
import { PeoplesApiService } from "src/app/models/peoples/peoples-api.model";
import { PeopleItemDto } from "../list/list.component";
import { PeopleFormComponent } from "../shared/people-form/people-form.component";
import { PeopleInputDto } from "src/app/models/peoples/people.model";

@Component({
  selector: "app-detail",
  templateUrl: "./detail.component.html",
  styleUrls: ["./detail.component.css"],
})
export class PeopleDetailComponent implements OnInit {
  userId: number;
  user: PeopleInputDto;
  loading = false;
  editing = false;

  form = new FormGroup({
    user: new FormControl(null),
  });
  errorField: string = null;

  @ViewChild("userForm") userForm: PeopleFormComponent;

  constructor(
    private route: ActivatedRoute,
    private service: PeoplesApiService,
    private auth: AuthService,
    private snackbar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.userId = parseInt(this.route.snapshot.paramMap.get("id"));
    this.loading = true;
    this.service.getById(this.userId).subscribe({
      next: (p) => {
        this.loading = false;
        this.value = p;
        this.form.disable();
      },
      error: (err) => {
        this.loading = false;
        console.error(err);
      },
    });
  }

  set value(p: PeopleInputDto) {
    this.user = p;
    this.form.reset({ user: p });
  }

  submit() {
    this.loading = true;
    const updated: PeopleInputDto = {
      id: this.userId,
      mail: this.form.value.user.mail,
      name: this.form.value.user.name,
      role: this.form.value.user.role ?? this.user.role,
      substituteId: this.form.value.user.substituteId
    };
    this.service.update(this.userId, updated).subscribe({
      next: () => {
        this.loading = false;
        this.value = updated;
        this.cancel();
        this.snackbar.open(
          "Les modifications ont été sauvegardées.",
          "D'accord",
          { duration: 3000 }
        );
      },
      error: (err) => {
        this.loading = false;
        this.handleError(err);
      },
    });
  }

  edit() {
    this.editing = true;
    this.form.enable();
    if (this.auth.id == this.userId) this.userForm.roleControl.disable();
  }

  cancel() {
    this.form.reset({ user: this.user });
    this.editing = false;
    this.form.disable();
  }

  handleError(error) {
    let errorMessage =
      "Une erreur est survenue, veuillez réessayer ultérieurement.";
    if (error.error) this.errorField = error.error;
    else this.snackbar.open(errorMessage, "D'accord", { duration: 3000 });
  }
}
