import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const auth = localStorage.getItem('token');
    const authReq = auth ? req.clone({
      setHeaders: {
        Authorization: `Bearer ${auth}`
      }
    }) : req;
    return next.handle(authReq);
  }
}