<mat-grid-list [cols]="getCols()" rowHeight="14rem" (window:resize)="onResize($event)">
    <mat-grid-tile>
        <mat-card>
            <mat-card-header>
                <mat-card-title>Nombre de visiteurs sur site</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                {{numberVisitors}} visiteurs
            </mat-card-content>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile>
        <mat-card>
            <mat-card-header>
                <mat-card-title>Moyenne visites / j</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                {{averageVisitors}} visites
            </mat-card-content>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile>
        <mat-card>
            <mat-card-header>
                <mat-card-title>Moyenne durée visite</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                {{smartHours(averageTime)}}
            </mat-card-content>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile>
        <mat-card>
            <mat-card-header>
                <mat-card-title>Taux de planification</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <mat-progress-spinner [matTooltip]="(planificationrate * 100).toFixed(2) + ' %'" [color]="colorOf(planificationrate)" [mode]="planificationrate ? 'determinate' : 'undeterminate'" [value]="planificationrate * 100">
                </mat-progress-spinner>
            </mat-card-content>
        </mat-card>
    </mat-grid-tile>
</mat-grid-list>