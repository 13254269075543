import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import "rxjs/add/operator/catch";
import { environment } from "src/environments/environment";
import { Planification, Visitor } from "./visitor.model";

@Injectable()
export class VisitorsApiService {
  API_URL: string;
  constructor(private http: HttpClient) {
    this.API_URL = environment.api;
  }

  private static _handleError(err: HttpErrorResponse | any) {
    return Observable.throw(
      err.message || "Error: Unable to complete request."
    );
  }

  async getvisitors(): Promise<Visitor[]> {
    return await this.http
      .get<Visitor[]>(`${this.API_URL}visitors`)
      .toPromise();
  }

  async getvisitorsInside(): Promise<Visitor[]> {
    return await this.http
      .get<Visitor[]>(`${this.API_URL}visitors/inside`)
      .toPromise();
  }

  async getvisitorsLogs(): Promise<Visitor[]> {
    return await this.http
      .get<Visitor[]>(`${this.API_URL}visitors/logs`)
      .toPromise();
  }

  async addVisitor(visitor: any): Promise<Visitor> {
    return await this.http
      .post<Visitor>(`${this.API_URL}visitors`, visitor)
      .toPromise();
  }

  async exitVisitor(id: number, pin: number): Promise<boolean> {
    return await this.http
      .get<boolean>(`${this.API_URL}visitors/exit/${id}/${pin}`)
      .toPromise();
  }

  async addPrevisitor(visitor: any): Promise<Visitor> {
    return await this.http
      .post<Visitor>(`${this.API_URL}visitors/planified`, visitor)
      .toPromise();
  }

  async entryPrevisitor(visitors: number[], pin: number): Promise<Boolean> {
    return await this.http
      .post<Boolean>(`${this.API_URL}visitors/entry/${pin}`, visitors)
      .toPromise();
  }

  async cancelPrevisitor(visitors: number[], pin: number): Promise<boolean> {
    return await this.http
      .post<boolean>(`${this.API_URL}visitors/cancel/${pin}`, visitors)
      .toPromise();
  }

  async getPrevisitors(): Promise<Planification[]> {
    return await this.http
      .get<Planification[]>(`${this.API_URL}visitors/planified`)
      .toPromise();
  }

  async importVisitors(stuff: any) {
    return await this.http
      .post<null>(`${this.API_URL}visitors/import`, stuff)
      .toPromise();
  }
}
