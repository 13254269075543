import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSelectModule } from "@angular/material/select";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTableModule } from "@angular/material/table";
import { PeopleCreationComponent } from "./creation/creation.component";
import { PeopleDetailComponent } from "./detail/detail.component";
import { PeopleListComponent } from "./list/list.component";
import { PeopleRoutingModule } from "./people-routing.module";
import { PeopleComponent } from "./people.component";
import { PeopleFormComponent } from "./shared/people-form/people-form.component";
import { SelectionInputModule } from "../shared/selection-input/selection-input.module";

@NgModule({
  declarations: [
    PeopleComponent,
    PeopleListComponent,
    PeopleFormComponent,
    PeopleCreationComponent,
    PeopleDetailComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PeopleRoutingModule,
    MatTableModule,
    MatPaginatorModule,
    MatButtonModule,
    MatIconModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatProgressBarModule,
    MatDialogModule,
    MatSnackBarModule,
    SelectionInputModule
],
  exports: [PeopleFormComponent]
})
export class PeopleModule {}
