<h3>Visites planifiées</h3>

<button mat-raised-button color="primary" (click)="confirmEntry()">Valider</button>
<button mat-raised-button color="secondary" (click)="cancelEntry()">Annuler</button>
<mat-selection-list #visitors [multiple]="true">
    <mat-list-option *ngFor="let visitor of visitorsList" [value]="visitor">
        {{visitor.name}} de la société {{visitor.companyName}} prévu pour le {{visitor.visit.toLocaleDateString()}} à
        {{visitor.visit.toLocaleTimeString()}} avec
        {{visitor.visitedStaffName}}
    </mat-list-option>
</mat-selection-list>