<mat-form-field class="example-full-width">
  <mat-label>{{ label }}</mat-label>
  <mat-spinner matSuffix diameter="24" *ngIf="loading"></mat-spinner>
  <input type="text" placeholder="Commencez à taper pour rechercher" aria-label="Number" matInput #inputRef
    (input)="find($event)" [matAutocomplete]="auto" [formControl]="input" autocomplete="off">
  <button type="button" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="clear()" *ngIf="input.value && !loading">
    <mat-icon>close</mat-icon>
  </button>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="select($event)">
    <mat-option *ngFor="let option of options" [value]="option">
      <img class="example-option-img" aria-hidden [src]="option.image" height="25">
      <span> {{option.title}}{{ option.subtitle ? ' | ' : ''}}<small>{{option.subtitle}}</small> </span>
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="input.hasError('required')">
    Veuillez compléter ce champ.
  </mat-error>
</mat-form-field>