import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PeopleCreationComponent } from "./creation/creation.component";
import { PeopleDetailComponent } from "./detail/detail.component";
import { PeopleListComponent } from "./list/list.component";
import { PeopleComponent } from "./people.component";

const routes: Routes = [
  {
    path: "",
    component: PeopleComponent,
    children: [
      {
        path: "",
        component: PeopleListComponent,
      },
      {
        path: "new-user",
        component: PeopleCreationComponent,
      },
      {
        path: ":id",
        component: PeopleDetailComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PeopleRoutingModule {}
