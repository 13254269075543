import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export type ActionDialogContent = {
  title: string;
  text: string;
  buttons: { label: string; method: () => any}[]
}

@Component({
  selector: "app-action-dialog",
  templateUrl: "./action-dialog.component.html",
  styleUrls: ["./action-dialog.component.css"],
})
export class ActionDialogComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<ActionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ActionDialogContent
  ) {}

  ngOnInit(): void {}

  act(button: { label: string; color: string; method: () => any }) {
    this.dialogRef.close(button.method());
  }
}
