<h3>Paramètres du compte</h3>

<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
<mat-tab-group>
  <mat-tab label="Informations personnelles">
    <form (submit)="updateSubstitute()">
      <app-people-form [formControl]="userForm"></app-people-form>
      <button mat-raised-button color="primary" type="submit" [disabled]="userForm.invalid">Modifier le remplaçant</button>
    </form>
    <form [formGroup]="avatarForm" (submit)="saveAvatar()">
      <div class="file-input">
        <mat-label>Photo de profil</mat-label>
        <input type="file" name="avatar" id="avatar" accept="image/*" #filePicker (change)="onImagePicked($event)"
          autocomplete="off">
      </div>
      <button mat-raised-button color="primary" type="submit" [disabled]="avatarForm.invalid">Modifier la photo de
        profil</button>
    </form>
  </mat-tab>

  <mat-tab label="Mot de passe">
    <form [formGroup]="passwordForm" (submit)="changePassword()">
      <mat-form-field>
        <mat-label>Mot de passe actuel</mat-label>
        <input type="password" name="currentPassword" id="currentPassword" matInput placeholder="Mot de passe actuel"
          formControlName="currentPassword" autocomplete="off">
        <mat-hint>
          En cas d'oubli de votre mot de passe, veuillez vous déconnecter et cliquer sur "Mot de passe oublié".
        </mat-hint>
        <mat-error>
          <p *ngIf="passwordForm.controls['currentPassword'].hasError('incorrectCurrent'); else otherCurrentPwdError">
            Votre mot de passe actuel n'est pas correct.</p>
          <ng-template #otherCurrentPwdError>Veuillez saisir votre mot de passe actuel.</ng-template>
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Nouveau mot de passe</mat-label>
        <input type="password" name="newPassword" id="newPassword" matInput placeholder="Nouveau mot de passe"
          formControlName="newPassword" autocomplete="off">
        <mat-error>
          Veuillez saisir votre nouveau mot de passe.
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Confirmation du mot de passe</mat-label>
        <input type="password" name="confirmedPassword" id="confirmedPassword" matInput
          placeholder="Confirmation du mot de passe" formControlName="confirmedPassword" autocomplete="off">
        <mat-error>
          <p *ngIf="passwordForm.controls['confirmedPassword'].hasError('confirmed'); else noConfirmedPassword">Les mots
            de passe ne correspondent pas.</p>
          <ng-template #noConfirmedPassword>Veuillez confirmer votre nouveau mot de passe.</ng-template>
        </mat-error>
      </mat-form-field>
      <button mat-raised-button color="primary" type="submit" [disabled]="passwordForm.invalid">Modifier mon mot de
        passe</button>
    </form>
  </mat-tab>

</mat-tab-group>