import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertComponent } from '../alert/alert.component';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { Planification, Visitor } from '../models/visitors/visitor.model';
import { VisitorsApiService } from '../models/visitors/visitors-api.model';
import { TextModalComponent } from '../text-modal/text-modal.component';
;

@Component({
  selector: 'app-planification',
  templateUrl: './planification.component.html',
  styleUrls: ['./planification.component.css']
})
export class PlanificationComponent implements OnInit {

  public visitorsList: Array<Planification>;
  @ViewChild("visitors") visitors;

  constructor(private visitorsAPI: VisitorsApiService, private dialog: MatDialog, private router: Router) {
    this.visitorsList = new Array();
    this.init();
  }

  async init() {
    (await this.visitorsAPI.getPrevisitors()).forEach(element => {
      //element.entry = new Date(element.entry);
      element.visit = new Date(element.visit);
      this.visitorsList.push(element);
    });
    if (this.visitorsList.length == 0) {
      this.showAlert("Aucune visité n'est planifiée !").then(() => {
        this.router.navigateByUrl('/');
      });
    }
  }

  async confirmEntry() {
    const dialogRef = this.dialog.open(TextModalComponent, {
      width: '70%',
      data: { question: `Saisir le PIN d'entrée:`, field: 'PIN' }
    });

    await dialogRef.afterClosed().toPromise().then(async result => {
      if (!result) { this.showAlert("Une erreur s'est produite !"); return; };
      this.entry(this.visitors.selectedOptions.selected.map(s => s.value), result);
    });
  }
  
  async cancelEntry() {
    const dialogRef = this.dialog.open(TextModalComponent, {
      width: '70%',
      data: { question: `Saisir le PIN d'entrée:`, field: 'PIN' }
    });
    await dialogRef.afterClosed().toPromise().then(async result => {
      if (!result) { this.showAlert("Une erreur s'est produite !"); return; };
      this.cancel(this.visitors.selectedOptions.selected.map(s => s.value), result);
    });
  }

  entry(visitors: Planification[], pin: number) {
    this.visitorsAPI.entryPrevisitor(visitors.map(v => v.id), pin).then(result => {
      if (result) {
        this.showAlert("Le visiteur est bien enregistré comme entré !").then(() => {
          this.router.navigateByUrl(`/`);
        });
      } else {
        this.showAlert("Erreur: PIN incorrect !")
      }
    });
  }

  cancel(visitors: Visitor[], pin: number) {
    this.visitorsAPI.cancelPrevisitor(visitors.map(v => v.id), pin).then(result => {
      if (result) {
        this.showAlert("Les visites ont été annulées").then(() => {
          this.router.navigateByUrl(`/`);
        });
      } else {
        this.showAlert("Erreur: PIN incorrect !")
      }
    });
  }

  ngOnInit() {
  }

  async showAlert(msg: string) {
    const dialogRef = this.dialog.open(AlertComponent, {
      width: '70%',
      data: { message: msg }
    });

    await dialogRef.afterClosed().toPromise().then(async result => {
    });
  }

}
