import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";
@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {

  logged: boolean = false;

  constructor(private router: Router, private auth: AuthService) { 
    this.auth.logged.subscribe((logged) => this.logged = logged);  
  }



  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.logged) return true;
    this.router.navigate(["login"], {
      queryParams: { action: "expiredToken" },
    });
    return false;
  }
}
