import { Component, OnInit } from "@angular/core";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { BehaviorSubject } from "rxjs";
import { debounceTime, switchMap, tap } from "rxjs/operators";
import { Role } from "src/app/auth.service";
import { People } from "src/app/models/peoples/people.model";
import { PeoplesApiService } from "src/app/models/peoples/peoples-api.model";
import {
  ColumnFilter,
  ColumnSorter,
  FilterOperator,
  MatchMode,
  OrderMode,
  SearchParams,
} from "src/app/models/search/search.model";
import { ActionDialogComponent } from "src/app/shared/action-dialog/action-dialog.component";

export type PeopleItemDto = {
  id: number;
  name: string;
  mail: string;
  role: Role
};

@Component({
  selector: "app-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.css"],
})
export class PeopleListComponent implements OnInit {
  searchForm = new FormGroup({
    pattern: new FormControl(null),
    role: new FormControl(null),
    pageNumber: new FormControl(0),
    pageSize: new FormControl(25),
    sorters: new FormArray([]),
  });
  searchParamsSubject = new BehaviorSubject<SearchParams>(null);

  items: PeopleItemDto[] = [];
  total: number = 0;
  loading = false;

  columns: { id; label }[] = [
    {
      id: "id",
      label: "Identifiant",
    },
    { id: "name", label: "Nom" },
    { id: "mail", label: "Adresse mail" },
  ];

  constructor(
    private service: PeoplesApiService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.initialSearch();
    this.registerSearchParamsChange();
    this.registerSearchFormValueChange();
  }

  private initialSearch() {
    const params = this.getParams(this.searchForm.value);
    this.searchParamsSubject.next(params);
  }

  private registerSearchParamsChange() {
    this.searchParamsSubject
      .pipe(
        tap(() => (this.loading = true)),
        debounceTime(200),
        switchMap((query) => {
          return this.service.search(query);
        })
      )
      .subscribe({
        next: (res) => {
          this.loading = false;
          this.items = res.items;
          this.total = res.total;
        },
        error: (err) => {
          this.loading = false;
          console.error(err);
        },
      });
  }

  private registerSearchFormValueChange() {
    this.searchForm.valueChanges.subscribe((v) => {
      const params = this.getParams(v);
      this.searchParamsSubject.next(params);
    });
  }

  getParams(values): SearchParams {
    const filters: ColumnFilter[] = [];
    if (values.pattern) {
      const rules = [
        { filterOperator: FilterOperator.CONTAINS, value: values.pattern },
      ];
      filters.push({
        column: "name",
        matchmode: MatchMode.ALL,
        rules: rules,
      });
    }
    if (values.role == 0 || values.role == 1) {
      filters.push({
        column: "role",
        matchmode: MatchMode.ALL,
        rules: [{ filterOperator: FilterOperator.EQUAL, value: values.role }],
      });
    }
    const sorters: ColumnSorter[] = [];
    if (values.sorters) {
      (values.sorters as Array<any>).forEach((s) => {
        if (s.column != null && s.mode != null)
          sorters.push({ column: s.column, mode: s.mode });
      });
    }
    return {
      filters: filters,
      sorters: sorters,
      pagination: { pageNumber: values.pageNumber, pageSize: values.pageSize },
    };
  }

  handlePaginationChange(p) {
    const values = this.searchForm.value;
    this.searchForm.setValue(
      {
        pattern: values.pattern,
        sorters: values.sorters,
        role: values.role,
        pageNumber: p.pageIndex,
        pageSize: p.pageSize,
      },
      { emitEvent: true }
    );
  }

  get sorters() {
    return this.searchForm.controls["sorters"] as FormArray;
  }

  addSorter() {
    this.sorters.push(
      new FormGroup({
        column: new FormControl(null),
        mode: new FormControl(OrderMode.ASC),
      })
    );
  }

  removeSorter(index) {
    this.sorters.removeAt(index);
  }

  delete(item) {
    const dialogRef = this.openDeletionDialog();
    dialogRef.afterClosed().subscribe((r: People) => {
      if (r) {
        this.loading = true;
        this.service.delete(item.id).subscribe({
          next: (r) => {
            this.loading = false;
            this.items = this.items.filter((item) => item.id !== r.id);
            --this.total;
            this.snackbar.open(
              `L'utilisateur "${item.name}" (${item.id}) a bien été supprimé.`,
              "D'accord",
              { duration: 3000 }
            );
          },
          error: (err) => {
            this.loading = false;
            this.snackbar.open(
              "Echec de la suppression. Veuillez réessayer ultérieurement.",
              "D'accord"
            );
          },
        });
      }
    });
  }

  private openDeletionDialog() {
    return this.dialog.open(ActionDialogComponent, {
      data: {
        title: "Suppression d'un utilisateur",
        text: "Êtes-vous sûr de vouloir supprimer cet utilisateur ? Cette opération est définitive.",
        buttons: [
          {
            color: "primary",
            label: "Supprimer",
            method: () => {
              return true;
            },
          },
          {
            color: "secondary",
            label: "Annuler",
            method: () => {
              return false;
            },
          },
        ],
      },
    });
  }
}
