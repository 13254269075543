import {
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { AuthService, Role } from "src/app/auth.service";

@Component({
  selector: "app-people-form",
  templateUrl: "./people-form.component.html",
  styleUrls: ["./people-form.component.css"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => PeopleFormComponent),
    },
    {
      provide: NG_VALIDATORS,
      useExisting: PeopleFormComponent,
      multi: true,
    },
  ],
})
export class PeopleFormComponent
  implements OnInit, OnChanges, ControlValueAccessor
{
  form: FormGroup = new FormGroup({
    name: new FormControl(null, [
      Validators.required,
      Validators.maxLength(255),
    ]),
    mail: new FormControl(null, [
      Validators.required,
      Validators.email,
      Validators.maxLength(255),
    ]),
    role: new FormControl(Role.USER, Validators.required),
    substituteId: new FormControl(null),
  });

  @Input() parentErrorField: string;

  constructor(private auth: AuthService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    const err = changes["parentErrorField"]?.currentValue;
    if (!err) return;
    switch (err) {
      case "mail":
        this.mailControl.setErrors({ conflict: true });
        break;
      case "role":
        this.roleControl.setErrors({ conflict: true });
        break;
      case "substitute":
        this.substituteControl.setErrors({ conflict: true });
        break;
    }
  }

  get mailControl() {
    return this.form.controls["mail"];
  }

  get nameControl() {
    return this.form.controls["name"];
  }

  get roleControl() {
    return this.form.controls["role"];
  }

  get substituteControl() {
    return this.form.controls["substituteId"];
  }

  writeValue(obj: any): void {
    if (obj) {
      this.form.setValue({
        name: obj.name ?? null,
        mail: obj.mail ?? null,
        role: obj.role ?? Role.USER,
        substituteId: obj.substituteId ?? null,
      });
    }
  }
  registerOnChange(fn: any): void {
    if (fn) {
      this.form.valueChanges.subscribe((v) => {
        fn(v);
      });
    }
  }
  registerOnTouched(fn: any): void {}
  setDisabledState?(isDisabled: boolean): void {
    this.auth.role.subscribe(role => {
      if (isDisabled) this.form.disable();
      else if (role == Role.USER) this.substituteControl.enable();
      else this.form.enable();
    })
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return {
      ...this.mailControl.errors,
      ...this.nameControl.errors,
      ...this.roleControl.errors,
    };
  }

  get mailControlError() {
    return this.mailControl.hasError("conflict")
      ? "Cette adresse mail est déjà utilisée."
      : "Une adresse mail valide est requise.";
  }
}
