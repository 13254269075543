<form [formGroup]="settingsForm">
    <mat-form-field>
        <mat-label>Capacité acceuil</mat-label>
        <input type="number" formControlName="maxVisitors" matInput autocomplete="off">
        <mat-error *ngIf="settingsForm.get('maxVisitors').hasError('required')">
            Un nombre est <strong>obligatoire</strong>
        </mat-error>
        <p matSuffix>visiteurs</p>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Durée anormale</mat-label>
        <input type="number" formControlName="anormalDuration" matInput autocomplete="off">
        <mat-error *ngIf="settingsForm.get('anormalDuration').hasError('required')">
            Un nombre est <strong>obligatoire</strong>
        </mat-error>
        <p matSuffix>h</p>
    </mat-form-field>
</form>