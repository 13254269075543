import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./auth.guard";
import { HomeComponent } from "./home/home.component";
import { LoginComponent } from "./login/login.component";
import { LogsComponent } from "./logs/logs/logs.component";
import { ParametersComponent } from "./params/parameters/parameters.component";
import { PlanificationComponent } from "./planification/planification.component";
import { PreregisterComponent } from "./preregister/preregister.component";
import { RegisterComponent } from "./register/register.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { StatisticsComponent } from "./stats/statistics/statistics.component";
import { AdminGuard } from "./admin.guard";
import { ImportComponent } from "./import/import.component";
import { AccountComponent } from "./account/account.component";

const routes: Routes = [
  { path: "", component: HomeComponent, canActivate: [AuthGuard] },
  { path: "login", component: LoginComponent },
  { path: "password-reset", component: ResetPasswordComponent },
  { path: "register", component: RegisterComponent, canActivate: [AuthGuard] },
  {
    path: "preregister",
    component: PreregisterComponent,
    canActivate: [AuthGuard],
  },
  { path: "logs", component: LogsComponent, canActivate: [AuthGuard] },
  {
    path: "planification",
    component: PlanificationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "configuration",
    component: ParametersComponent,
    canActivate: [AdminGuard],
  },
  {
    path: "users",
    loadChildren: () =>
      import("./people/people.module").then((m) => m.PeopleModule),
    canActivate: [AdminGuard],
  },
  {
    path: "statistics",
    component: StatisticsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "import",
    component: ImportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "me",
    component: AccountComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "**",
    redirectTo: "",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
