import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { SelectionItem } from "./selection-input.component";

@Injectable({
  providedIn: "root",
})
export class SelectionInputService {
  private readonly API_URL: string;

  constructor(private http: HttpClient) {
    this.API_URL = environment.api;
  }

  find<T>(endpoint: string, pattern: string) {
    const stringStruct = { value: pattern };
    return this.http.post<SelectionItem<T>[]>(
      `${this.API_URL}${endpoint}/find`,
      stringStruct
    );
  }

  findById<T>(endpoint: string, id: T) {
    return this.http.get<SelectionItem<T>>(
      `${this.API_URL}${endpoint}/find/${id}`
    );
  }
}
