import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatBadgeModule } from "@angular/material/badge";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTableModule } from "@angular/material/table";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"; // module
import { MDBBootstrapModule } from "angular-bootstrap-md";
import { Ng2SmartTableModule } from "ng2-smart-table";
import { AlertComponent } from "./alert/alert.component";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CompanyModalComponent } from "./company-modal/company-modal.component";
import { ConfirmModalComponent } from "./confirm-modal/confirm-modal.component";
import { HomeComponent } from "./home/home.component";
import { LoginComponent } from "./login/login.component";
import { PasswordResetDialogComponent } from "./login/password-reset-dialog/password-reset-dialog.component";
import { LogsModule } from "./logs/logs.module";
import { CompaniesApiService } from "./models/companies/companies-api.model";
import { AuthInterceptor } from "./models/interceptor";
import { PeoplesApiService } from "./models/peoples/peoples-api.model";
import { ReasonsApiService } from "./models/reason/reasons-api.model";
import { VisitorsApiService } from "./models/visitors/visitors-api.model";
import { ParamsModule } from "./params/params.module";
import { PeopleModalComponent } from "./people-modal/people-modal.component";
import { PlanificationComponent } from "./planification/planification.component";
import { PreregisterComponent } from "./preregister/preregister.component";
import { RegisterComponent } from "./register/register.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { StatsModule } from "./stats/stats.module";
import { TextModalComponent } from "./text-modal/text-modal.component";
import { ImportComponent } from "./import/import.component";
import { AccountComponent } from './account/account.component';
import { PeopleModule } from "./people/people.module";
import { MatTabsModule } from "@angular/material/tabs";
import { SelectionInputModule } from "./shared/selection-input/selection-input.module";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    RegisterComponent,
    TextModalComponent,
    PeopleModalComponent,
    CompanyModalComponent,
    ConfirmModalComponent,
    AlertComponent,
    PreregisterComponent,
    PlanificationComponent,
    LoginComponent,
    ImportComponent,
    ResetPasswordComponent,
    PasswordResetDialogComponent,
    AccountComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    Ng2SmartTableModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatBadgeModule,
    MDBBootstrapModule.forRoot(),
    MatInputModule,
    MatSelectModule,
    MatCardModule,
    AppRoutingModule,
    ReactiveFormsModule,
    MatDialogModule,
    FormsModule,
    MatGridListModule,
    MatTableModule,
    MatTooltipModule,
    MatMenuModule,
    ParamsModule,
    StatsModule,
    MatProgressBarModule,
    LogsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    PeopleModule,
    MatTabsModule,
    SelectionInputModule
],
  providers: [
    VisitorsApiService,
    CompaniesApiService,
    PeoplesApiService,
    ReasonsApiService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
