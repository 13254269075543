import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ParamsService {
  API_URL: string;

  constructor(private http: HttpClient) {
    this.API_URL = environment.api;
  }

  async getMaxVisitors(): Promise<number> {
    return await this.http
      .get<number>(`${this.API_URL}parameters/maxvisitors`)
      .toPromise();
  }

  async getAnormalDuration(): Promise<number> {
    return await this.http
      .get<number>(`${this.API_URL}parameters/anormalduration`)
      .toPromise();
  }

  async setAnormalDuration(anormalDuration: number): Promise<boolean> {
    return await this.http
      .post<boolean>(`${this.API_URL}parameters/anormalduration`, {
        value: anormalDuration,
      })
      .toPromise();
  }

  async setMaxVisitors(maxVisitors: number): Promise<boolean> {
    return await this.http
      .post<boolean>(`${this.API_URL}parameters/maxvisitors`, {
        value: maxVisitors,
      })
      .toPromise();
  }
}
