import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import "rxjs/add/operator/catch";
import { PeopleItemDto } from "src/app/people/list/list.component";
import { environment } from "src/environments/environment";
import { SearchParams, SearchResult } from "../search/search.model";
import { People, PeopleInputDto } from "./people.model";

@Injectable()
export class PeoplesApiService {
  API_URL: string;
  constructor(private http: HttpClient) {
    this.API_URL = environment.api + "people/";
  }

  private static _handleError(err: HttpErrorResponse | any) {
    return Observable.throw(
      err.message || "Error: Unable to complete request."
    );
  }

  async getPeoples(): Promise<People[]> {
    return this.http.get<People[]>(`${this.API_URL}`).toPromise();
  }

  async getPeople(id: number): Promise<People> {
    return await this.http.get<People>(`${this.API_URL}${id}`).toPromise();
  }

  /**
   * Créer un utilisateur.
   * @param people
   * @returns
   * @deprecated
   */
  async addPeople(people: People): Promise<People> {
    return await this.http.post<People>(`${this.API_URL}`, people).toPromise();
  }

  public search(params: SearchParams) {
    return this.http.post<SearchResult<PeopleItemDto>>(
      `${this.API_URL}search`,
      params
    );
  }

  /**
   * Supprimer un utilisateur.
   * @param id L'identifiant de l'utilisateur.
   * @returns L'utilisateur supprimé.
   */
  public delete(id: number) {
    return this.http.delete<People>(`${this.API_URL}${id}`);
  }

  /**
   * Ajouter un nouvel utilisateur.
   * @param people Les informations de l'utilisateur.
   * @returns Les informations essentielles de l'utilisateur (nom, mail, rôle et id).
   */
  public add(people: PeopleItemDto) {
    return this.http.post<PeopleItemDto>(`${this.API_URL}`, people);
  }

  /**
   * Obtenir les informations essentielles d'un utilisateur.
   * @param id L'identifiant de l'utilisateur.
   * @returns Les informations essentielles de l'utilisateur (nom, mail, rôle et id).
   */
  public getById(id: number) {
    return this.http.get<PeopleInputDto>(`${this.API_URL}${id}`);
  }

  /**
   * Mettre à jour un utilisateur.
   * @param id L'identifiant de l'utilisateur.
   * @param data
   * @returns
   */
  public update(id: number, data: PeopleItemDto) {
    return this.http.put(`${this.API_URL}${id}`, data);
  }

  public updateProfilePicture(file: File) {
    const formData = new FormData();
    formData.append("file", file);
    return this.http.put(`${this.API_URL}avatar`, formData);
  }

  /**
   * Modifier le mot de passe de l'utilisateur connecté.
   * @param currentPassword Le mot de passe actuel.
   * @param newPassword Le nouveau mot de passe.
   * @returns Un Observable de succès.
   */
  public changePassword(currentPassword: string, newPassword: string) {
    const pwd = {
      newPassword: newPassword,
      currentPassword: currentPassword,
    };
    return this.http.put(`${this.API_URL}password`, pwd);
  }

  public updateSubstitute(substituteId: number) {
    const data = { value: substituteId };
    return this.http.put(`${this.API_URL}substitute`, data);
  }
}
