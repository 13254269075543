<mat-toolbar color="primary">
  <mat-toolbar-row>
    <a routerLink="/"><img src="./assets/img/adti.png" /></a>
    <a class="app-name" routerLink="/">
      <h1>&nbsp;RegElec</h1>
    </a>
    <span class="example-spacer"></span>
    <div *ngIf="isLogged">
      <button mat-icon-button [matMenuTriggerFor]="menu" matTooltip="Enregistrer un visiteur">
        <mat-icon>person_add</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item routerLink="register">Entrée manuelle</button>
        <button mat-menu-item routerLink="planification">Valider une entrée</button>
        <button mat-menu-item routerLink="preregister">Prévoir une entrée</button>
        <button mat-menu-item routerLink="import">Importer un fichier</button>
      </mat-menu>
      <button *ngIf="role == 0" mat-icon-button [matMenuTriggerFor]="settingsMenu" matTooltip="Configuration">
        <mat-icon>settings</mat-icon>
      </button>
      <mat-menu #settingsMenu="matMenu">
        <button mat-menu-item routerLink="configuration">Paramètres</button>
        <button mat-menu-item routerLink="users">Utilisateurs</button>
      </mat-menu>
      <button mat-icon-button matTooltip="Statistiques" routerLink="statistics">
        <mat-icon>bar_chart</mat-icon>
      </button>
      <button mat-icon-button matTooltip="Historique des visites" routerLink="logs">
        <mat-icon>inventory_2</mat-icon>
      </button>
      <button mat-icon-button matTooltip="Signaler une urgence" (click)="sendAlert()">
        <mat-icon>local_fire_department</mat-icon>
      </button>
      <button mat-icon-button class="image-btn" [matTooltip]="username" [matMenuTriggerFor]="accountMenu">
        <img *ngIf="avatar; else noAvatar" [src]="avatar" alt="">
        <ng-template #noAvatar>
          <mat-icon>account_circle</mat-icon>
        </ng-template>
      </button>
      <mat-menu #accountMenu="matMenu">
        <button mat-menu-item routerLink="me">Paramètres du compte</button>
        <button mat-menu-item (click)="logout()">
          Déconnexion
        </button>
      </mat-menu>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
<!-- main app container -->
<div class="page">
  <router-outlet></router-outlet>
</div>