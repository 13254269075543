<mat-form-field>
    <mat-label>Recherche</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Chiraux, 16:32, 15:, 18/12/2020, Installation ou florian@adti.fr" #input>
</mat-form-field>

<div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource">

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Visiteur </th>
            <td mat-cell *matCellDef="let visitor"> {{visitor.name}} </td>
        </ng-container>
        <ng-container matColumnDef="company">
            <th mat-header-cell *matHeaderCellDef> Société </th>
            <td mat-cell *matCellDef="let visitor"> {{visitor.company?.name}} </td>
        </ng-container>
        <ng-container matColumnDef="visited_staff">
            <th mat-header-cell *matHeaderCellDef> Personne visitée </th>
            <td mat-cell *matCellDef="let visitor"> {{visitor.visitedStaff?.name ? visitor.visitedStaff?.name : 'Utilisateur inconnu'}} </td>
        </ng-container>
        <ng-container matColumnDef="reason">
            <th mat-header-cell *matHeaderCellDef> Motif </th>
            <td mat-cell *matCellDef="let visitor"> {{visitor.reason?.label ? visitor.reason?.label : 'Indéfini'}} </td>
        </ng-container>
        <ng-container matColumnDef="entry">
            <th mat-header-cell *matHeaderCellDef> Heure d'arrivée </th>
            <td mat-cell *matCellDef="let visitor"> {{visitor.entry.toLocaleTimeString()}} </td>
        </ng-container>
        <ng-container matColumnDef="exit">
            <th mat-header-cell *matHeaderCellDef> Heure de sortie </th>
            <td mat-cell *matCellDef="let visitor"> {{visitor.exitTime.toLocaleTimeString()}} </td>
        </ng-container>
        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef> Date de visite </th>
            <td mat-cell *matCellDef="let visitor"> {{visitor.exitTime.toLocaleDateString()}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">Aucun visiteur correspondant à "{{input.value}}"</td>
        </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>