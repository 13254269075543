<div [formGroup]="form">
  <mat-form-field>
    <mat-label>Nom</mat-label>
    <input type="text" name="name" id="name" placeholder="Nom" matInput formControlName="name" autocomplete="off">
    <mat-error>
      L'identité de l'utilisateur est requise.
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Adresse mail</mat-label>
    <input type="email" name="mail" id="mail" placeholder="Adresse mail" matInput formControlName="mail"
      autocomplete="off">
    <mat-error>
      {{ mailControlError }}
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Type de profil</mat-label>
    <mat-select formControlName="role">
      <mat-option [value]="1">Utilisateur</mat-option>
      <mat-option [value]="0">Administrateur</mat-option>
    </mat-select>
    <mat-error>
      Il doit rester au moins un administrateur parmi les utilisateurs.
    </mat-error>
  </mat-form-field>

  <app-selection-input endpoint="people" label="Remplaçant" formControlName="substituteId"></app-selection-input>
  <mat-error *ngIf="substituteControl.hasError('conflict')">Le remplaçant doit être différent du compte
    modifié.</mat-error>
</div>