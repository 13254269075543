import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CompaniesApiService } from '../models/companies/companies-api.model';
import { Company } from '../models/companies/company.model';
import { PeoplesApiService } from '../models/peoples/peoples-api.model';
import { People } from '../models/peoples/people.model';
import { Reason } from '../models/reason/reason.model';
import { ReasonsApiService } from '../models/reason/reasons-api.model';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TextModalComponent } from '../text-modal/text-modal.component';
import { PeopleModalComponent } from '../people-modal/people-modal.component';
import { CompanyModalComponent } from '../company-modal/company-modal.component';
import { AlertComponent } from '../alert/alert.component';
import { Visitor } from '../models/visitors/visitor.model';
import { VisitorsApiService } from '../models/visitors/visitors-api.model';

@Component({
  selector: 'app-preregister',
  templateUrl: './preregister.component.html',
  styleUrls: ['./preregister.component.css']
})
export class PreregisterComponent implements OnInit {
  public registerForm: FormGroup;

  //Reason control
  public isOtherReason: boolean;
  public newReason?: Reason;

  //People control
  public isOtherPeople: boolean;
  public newPeople?: People;
  isOtherCompany: any;
  newCompany: Company;

  constructor(public dialog: MatDialog, private fb: FormBuilder, private visitorAPI: VisitorsApiService, private companyAPI: CompaniesApiService, private peopleAPI: PeoplesApiService, private reasonAPI: ReasonsApiService, private router: Router) {
    this.registerForm = this.fb.group({
      name: ['', Validators.required],
      company: ['', Validators.required],
      visited_staff: ['', Validators.required],
      reason: ['', Validators.required],
      phone: ['', [Validators.required, Validators.pattern("0[0-9]{10}|[0-9]{10}")]],
      mail: ['', [Validators.required, Validators.email]],
      visit: ['', Validators.required],
      comment: [''],
    });
  }

  async ngOnInit(): Promise<void> {
  }


  async checkIfOtherReason(reason: string) {
    if (reason == 'other') {
      this.isOtherReason = true;
      await this.openReasonDialog()
    };
  }

  async checkIfOtherPeople(people: string) {
    if (people == 'other') {
      this.isOtherPeople = true;
      await this.openPeopleDialog()
    };
  }

  async checkIfOtherCompany(company: string) {
    if (company == 'other') {
      this.isOtherCompany = true;
      await this.openCompanyDialog()
    };
  }

  async openReasonDialog(): Promise<void> {
    const dialogRef = this.dialog.open(TextModalComponent, {
      width: '250px',
      data: { question: `Quelle est la raison de la visite ?`, field: 'Raison' }
    });

    await dialogRef.afterClosed().toPromise().then(async result => {
      this.newReason = await this.reasonAPI.addReason({ id: 0, label: result })
    });
  }

  async openPeopleDialog(): Promise<void> {
    const dialogRef = this.dialog.open(PeopleModalComponent, {
      width: '250px',
      data: { question: `Quelle est la personne visitée ?`}
    });

    await dialogRef.afterClosed().toPromise().then(async result => {
      (result);
      this.newPeople = await this.peopleAPI.addPeople({ id: 0, name: result.name , mail: result.mail})
    });
  }

  async openCompanyDialog(): Promise<void> {
    const dialogRef = this.dialog.open(CompanyModalComponent, {
      width: '250px',
      data: { question: `Quelle est la société extérieure ?`}
    });

    await dialogRef.afterClosed().toPromise().then(async result => {
      this.newCompany = await this.companyAPI.addCompany({ id: 0, name: result.name , address: result.address, phone: result.phone})
    });
  }


  async onSubmit() {
    if (this.registerForm.invalid) {
      await this.showAlert("Champs incorrects !");
      return;
    }
    let visitor = {
      id: 0,
      name: this.registerForm.get('name').value,
      companyId: null,
      entry: new Date(Date.now()),
      hasLeft: false,
      exitTime: null,
      visitedStaffId: null,
      reasonId: null,
      phone: +this.registerForm.get('phone').value,
      pin: 0,
      mail: this.registerForm.get('mail').value,
      visit: this.registerForm.get('visit').value,
      hasEntered: false,
      comment: this.registerForm.get('comment').value || '',
    }
    await this.checkIfOtherReason(this.registerForm.get('reason').value);
    await this.checkIfOtherPeople(this.registerForm.get('visited_staff').value);
    await this.checkIfOtherCompany(this.registerForm.get('company').value);
    if (!this.isOtherReason) {
      visitor.reasonId = (await this.reasonAPI.getReason(this.registerForm.get('reason').value)).id;
    }
    else {
      visitor.reasonId = this.newReason.id
    };
    if (!this.isOtherPeople) {
      visitor.visitedStaffId = (await this.peopleAPI.getPeople(this.registerForm.get('visited_staff').value)).id;
    }
    else {
      visitor.visitedStaffId = this.newPeople.id
    };
    if (!this.isOtherCompany) {
      visitor.companyId = (await this.companyAPI.getCompany(this.registerForm.get('company').value)).id;
    }
    else {
      visitor.companyId = this.newCompany.id
    };
    await this.visitorAPI.addPrevisitor(visitor)
    await this.showAlert("Le visiteur est bien enregistré !");
    this.router.navigateByUrl(`/`);
  }

  async showAlert(msg: string) {
    const dialogRef = this.dialog.open(AlertComponent, {
      width: '70%',
      data: { message: msg }
    });

    await dialogRef.afterClosed().toPromise().then(async result => {
    });
  }
}
