import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { HttpClient } from "@angular/common/http";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
} from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-emergency-mails",
  templateUrl: "./emergency-mails.component.html",
  styleUrls: ["./emergency-mails.component.css"],
})
export class EmergencyMailsComponent implements OnInit {
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  addrCtrl = new FormControl();
  filteredAddrs: Observable<string[]>;
  addrs: string[] = [];
  allAddrs: string[] = [];

  @ViewChild("addrInput") addrInput: ElementRef<HTMLInputElement>;
  @ViewChild("auto") matAutocomplete: MatAutocomplete;
  API_URL: string;

  constructor(private client: HttpClient) {
    this.API_URL = environment.api;
    this.filteredAddrs = this.addrCtrl.valueChanges.pipe(
      startWith(null),
      map((addr: string | null) =>
        addr ? this._filter(addr) : this.allAddrs.slice()
      )
    );
  }

  ngOnDestroy(): void {
    let str = "";
    this.addrs.forEach((addr) => {
      str += addr + ",";
    });
    str = str.substring(0, str.length - 1);
    this.client
      .post(`${this.API_URL}parameters/addresses`, { text: str })
      .toPromise()
      .then(() => {});
  }

  ngOnInit(): void {
    this.client
      .get(`${this.API_URL}parameters/addresses`)
      .toPromise()
      .then((result: { text }) => {
        this.addrs = result.text.split(",");
      });
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our addr
    if ((value || "").trim()) {
      this.addrs.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = "";
    }

    this.addrCtrl.setValue(null);
  }

  remove(addr: string): void {
    const index = this.addrs.indexOf(addr);

    if (index >= 0) {
      this.addrs.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.addrs.push(event.option.viewValue);
    this.addrInput.nativeElement.value = "";
    this.addrCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allAddrs.filter(
      (addr) => addr.toLowerCase().indexOf(filterValue) === 0
    );
  }
}
