import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Reason } from "src/app/models/reason/reason.model";
import { ReasonsApiService } from "src/app/models/reason/reasons-api.model";

@Component({
  selector: "app-reason-creation-dialog",
  templateUrl: "./reason-creation-dialog.component.html",
  styleUrls: ["./reason-creation-dialog.component.css"],
})
export class ReasonCreationDialogComponent implements OnInit {
  loading: boolean = false;
  form = new FormGroup({
    reason: new FormControl(null),
  });
  conflictError = null;
  title: string = "Nouveau motif de visite";

  constructor(
    private service: ReasonsApiService,
    public dialogRef: MatDialogRef<ReasonCreationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Reason
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.title = "Modification du motif de visite";
      this.form.setValue({ reason: this.data.label });
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  submit() {
    this.loading = true;
    if (!this.data) this.add();
    else this.update();
  }

  private add() {
    this.service.add(this.form.value["reason"]).subscribe({
      next: (reason) => {
        this.conflictError = null;
        this.loading = false;
        this.dialogRef.close(reason);
      },
      error: (err) => {
        this.loading = false;
        this.conflictError = { conflict: true };
      },
    });
  }

  private update() {
    const toUpdate: Reason = new Reason(this.data.id, this.form.value['reason'])
    this.service.update(this.data.id, toUpdate).subscribe({
      next: (reason) => {
        this.conflictError = null;
        this.loading = false;
        this.dialogRef.close(toUpdate);
      },
      error: (err) => {
        this.loading = false;
        this.conflictError = { conflict: true };
      },
    });
  }
}
