import { Component, OnInit } from "@angular/core";
import {
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../auth.service";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.css"],
})
export class ResetPasswordComponent implements OnInit {
  resetId: string;

  passwordsForm = new FormGroup(
    {
      password: new FormControl(null, [Validators.required]),
      confirmation: new FormControl(null, [Validators.required]),
    },
    [this.samePasswordsValidator]
  );

  loading: boolean = false;
  errorMessage: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService
  ) {}

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params) => {
      this.resetId = params.get("requestId");
      if (!this.resetId) {
        this.errorMessage =
          "Le lien de réinitialisation est invalide ou a expiré.";
      }
    });
    this.passwordsForm.valueChanges.subscribe((v) =>
      console.log(this.passwordsForm)
    );
  }

  submit() {
    const { password } = this.passwordsForm.value;
    this.loading = true;
    this.auth.resetPassword(this.resetId, password).subscribe({
      next: () => {
        this.loading = false;
        this.router.navigate(["login"], {
          queryParams: { action: "resetPassword" },
        });
      },
      error: (err) => {
        this.loading = false;
        if (err?.status == 404 || err?.status == 400) {
          this.errorMessage =
            "Le lien de réinitialisation est invalide ou a expiré.";
        } else {
          this.errorMessage =
            "Une erreur est survenue, veuillez réessayer ultérieurement.";
        }
      },
    });
  }

  samePasswordsValidator(form: FormGroup): ValidationErrors {
    const { password, confirmation } = form.value;
    return !password || !confirmation || password != confirmation
      ? { passwordsNoMatch: true }
      : null;
  }
}
