import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService, Role } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class AdminGuard implements CanActivate {
  admin: boolean = false;

  constructor(private router: Router, private auth: AuthService) {
    this.auth.role.subscribe((role) => (this.admin = role == Role.ADMIN));
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.admin) return true;
    this.router.navigate([""]);
    return false;
  }
}
