<form [formGroup]="searchForm">
  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
  <mat-accordion>
    <mat-expansion-panel expanded>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Filtrer
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-form-field class="filter-form-field">
        <mat-label>Nom</mat-label>
        <input type="text" placeholder="Nom" matInput formControlName="pattern">
      </mat-form-field>
      <mat-form-field class="filter-form-field">
        <mat-label>Type de profil</mat-label>
        <mat-select formControlName="role">
          <mat-option>Indifférent</mat-option>
          <mat-option [value]="0">Administrateur</mat-option><mat-option [value]="1">Utilisateur</mat-option>
        </mat-select>
      </mat-form-field>
    </mat-expansion-panel>
    <mat-expansion-panel formArrayName="sorters">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Trier
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="sort-form-fields-group" *ngFor="let control of sorters.controls; let i = index" [formGroup]="control">
        <mat-form-field>
          <mat-label>Colonne</mat-label>
          <mat-select formControlName="column">
            <mat-option *ngFor="let column of columns" [value]="column.id">{{ column.label }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Ordre</mat-label>
          <mat-select formControlName="mode">
            <mat-option [value]="0">Croissant</mat-option>
            <mat-option [value]="1">Décroissant</mat-option>
          </mat-select>
        </mat-form-field>
        <button type="button" mat-mini-fab color="secondary" (click)="removeSorter(i)">
          <mat-icon>remove</mat-icon>
        </button>
      </div>
      <button type="button" mat-raised-button color="secondary" (click)="addSorter()">
        Ajouter un critère de tri
      </button>
    </mat-expansion-panel>
  </mat-accordion>
</form>

<div>
  <table mat-table [dataSource]="items" class="mat-elevation-z8">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>#</th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Nom</th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>
    <ng-container matColumnDef="mail">
      <th mat-header-cell *matHeaderCellDef>Email</th>
      <td mat-cell *matCellDef="let element"> {{element.mail}} </td>
    </ng-container>
    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef>Type de profil</th>
      <td mat-cell *matCellDef="let element"> {{element.role == 0 ? "Administrateur" : "Utilisateur"}} </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button type="button" (click)="delete(element)">
          <mat-icon>delete</mat-icon>
        </button>
        <button mat-icon-button type="button" [routerLink]="element.id">
          <mat-icon>edit</mat-icon>
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="['id', 'name', 'mail', 'role', 'actions']"></tr>
    <tr mat-row *matRowDef="let row; columns: ['id', 'name', 'mail', 'role', 'actions'];"></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" [attr.colspan]="5">
        Aucun utilisateur trouvé.
      </td>
    </tr>
  </table>
  <mat-paginator [length]="total" (page)="handlePaginationChange($event)" [pageSizeOptions]="[25, 50, 100]"
    showFirstLastButtons></mat-paginator>
</div>