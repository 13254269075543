import { Component, OnInit } from '@angular/core';
import { StaticticsService } from '../statictics.service';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.css']
})
export class StatisticsComponent implements OnInit {

  numberVisitors: number;
  averageVisitors: number;
  averageTime: number;
  planificationrate: number;
  window;

  constructor(private statisticsService: StaticticsService) {
    this.window = window;
    statisticsService.getNumberVisitors().subscribe(count => {
      this.numberVisitors = count;
    });
    statisticsService.getAverageVisitors().subscribe(count => {
      this.averageVisitors = count;
    });
    statisticsService.getAverageTime().subscribe(count => {
      this.averageTime = count;
    });
    statisticsService.getPlanificationRate().subscribe(count => {
      this.planificationrate = count;
    });
  }

  colorOf(rate: number) {
    if (rate <= 0.25) return 'warn';
    if (rate <= 0.5) return 'accent';
    return 'primary';
  }

  getCols() {
    if (window.innerWidth < 700) return 1;
    if (window.innerWidth < 1000) return 2;
    if (window.innerWidth < 1300) return 3;
    return 4;
  }

  ngOnInit(): void {
  }

  onResize(event) {
    this.ngOnInit();
  }

  smartHours(hours: number): string {
    const entireHours = Math.trunc(hours);
    const rawMinutes = (hours - entireHours) * 60;
    let minutesText = rawMinutes.toFixed(0);
    if (rawMinutes < 10) minutesText = '0' + minutesText;
    return entireHours + 'h' + minutesText;
  }

}
