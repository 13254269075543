import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AuthService } from "../auth.service";
import { PeopleInputDto } from "../models/peoples/people.model";
import { PeoplesApiService } from "../models/peoples/peoples-api.model";

@Component({
  selector: "app-account",
  templateUrl: "./account.component.html",
  styleUrls: ["./account.component.css"],
})
export class AccountComponent implements OnInit {
  user: PeopleInputDto = null;

  userForm = new FormControl(null);

  avatarForm = new FormControl(null, Validators.required);

  passwordForm = new FormGroup({
    currentPassword: new FormControl(null, Validators.required),
    newPassword: new FormControl(null, Validators.required),
    confirmedPassword: new FormControl(null, [Validators.required]),
  });

  loading = false;

  constructor(
    private service: PeoplesApiService,
    private auth: AuthService,
    private snackbar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.userForm.disable();
    const userId = this.auth.id;
    this.loading = true;
    this.service.getById(userId).subscribe({
      next: (p) => {
        this.loading = false;
        this.user = p;
        this.userForm.setValue(p);
        this.userForm.enable();
      },
    });
    this.registerPasswordFormChange();
  }

  registerPasswordFormChange() {
    this.passwordForm.valueChanges.subscribe((v) => {
      if (v.newPassword != v.confirmedPassword) {
        this.passwordForm.controls["confirmedPassword"].setErrors({
          confirmed: true,
        });
      }
    });
  }

  onImagePicked(e) {
    const file = (e.target as HTMLInputElement).files[0];
    this.avatarForm.setValue(file);
  }

  saveAvatar() {
    this.loading = true;
    this.service.updateProfilePicture(this.avatarForm.value).subscribe({
      next: (e: any) => {
        this.avatarForm.setValue(null);
        this.loading = false;
        this.snackbar.open(
          "Votre photo de profil a été modifiée.",
          "D'accord",
          { duration: 3000 }
        );
        this.auth.profilePicture.next("" + e.value);
      },
      error: (err) => {
        this.loading = false;
        this.snackbar.open(
          "Une erreur est survenue, veuillez réessayer ultérieurement.",
          "D'accord",
          { duration: 3000 }
        );
      },
    });
  }

  updateSubstitute() {
    console.log(this.userForm.value);
    const v = this.userForm.value.substituteId;
    if (v) {
      this.loading = true;
      this.service.updateSubstitute(v).subscribe({
        next: () => {
          this.loading = false;
          this.user.substituteId = v;
          this.snackbar.open("Votre remplaçant a été modifié.", "D'accord", {
            duration: 3000,
          });
        },
        error: (err) => {
          this.loading = false;
          const error =
            err.status == 409
              ? "Votre remplaçant ne peut pas être vous-même."
              : "Une erreur est survenue, veuillez réessayer ultérieurement.";
          this.snackbar.open(error, "D'accord", { duration: 3000 });
        },
      });
    }
  }

  changePassword() {
    this.loading = true;
    this.service
      .changePassword(
        this.passwordForm.value.currentPassword,
        this.passwordForm.value.newPassword
      )
      .subscribe({
        next: () => {
          this.loading = false;
          this.snackbar.open(
            "Votre nouveau mot de passe a été enregistré.",
            "D'accord",
            { duration: 3000 }
          );
        },
        error: (err) => {
          this.loading = false;
          if (err.status == 409) {
            this.passwordForm.controls["currentPassword"].setErrors({
              incorrectCurrent: true,
            });
          } else {
            this.snackbar.open(
              "Une erreur est survenue, veuillez réessayer ultérieurement.",
              "D'accord",
              { duration: 3000 }
            );
          }
        },
      });
  }
}
