import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import "rxjs/add/operator/catch";
import { environment } from "src/environments/environment";
import { SearchParams, SearchResult } from "../search/search.model";
import { Reason } from "./reason.model";

@Injectable()
export class ReasonsApiService {
  API_URL: string;
  constructor(private http: HttpClient) {
    this.API_URL = environment.api;
  }

  public search(params: SearchParams) {
    return this.http.post<SearchResult<Reason>>(
      `${this.API_URL}reasons/search`,
      params
    );
  }
  private static _handleError(err: HttpErrorResponse | any) {
    return Observable.throw(
      err.message || "Error: Unable to complete request."
    );
  }

  async getReasons(): Promise<Reason[]> {
    return this.http.get<Reason[]>(`${this.API_URL}reasons`).toPromise();
  }

  async getReason(id: number): Promise<Reason> {
    return await this.http
      .get<Reason>(`${this.API_URL}reasons/${id}`)
      .toPromise();
  }

  /**
   * Ajouter un nouveau motif de visite
   * @param reason Le motif de la visite
   * @returns
   * @deprecated
   */
  async addReason(reason: any): Promise<Reason> {
    return await this.http
      .post<Reason>(`${this.API_URL}reasons`, reason)
      .toPromise();
  }

  /**
   * Ajouter un motif de visite.
   * @param reason Le motif de la visite.
   * @returns Un Observable contenant le motif créé.
   */
  public add(reason: string): Observable<Reason> {
    const toAdd = new Reason("-1", reason);
    return this.http.post<Reason>(`${this.API_URL}reasons`, toAdd);
  }

  /**
   * Supprimer un motif de visite.
   * @param id L'identifiant du motif à supprimer.
   * @returns Un Observable contenant le motif supprimé.
   */
  public delete(id: number) {
    return this.http.delete(`${this.API_URL}reasons/${id}`);
  }

  /**
   * Modifier le label d'un motif de visite.
   * @param id L'identifiant du motif.
   * @param toUpdate Les nouvelles informations du motif.
   * @returns Le motif modifié.
   */
  update(id: string, toUpdate: Reason) {
    return this.http.put(`${this.API_URL}reasons/${id}`, toUpdate);
  }
}
