import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { People } from '../models/peoples/people.model';
import { Reason } from '../models/reason/reason.model';
import { PeoplesApiService } from '../models/peoples/peoples-api.model';
import { ReasonsApiService } from '../models/reason/reasons-api.model';
import { VisitorsApiService } from '../models/visitors/visitors-api.model';
import { MatDialog } from '@angular/material/dialog';
import { AlertComponent } from '../alert/alert.component';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.css']
})
export class ImportComponent implements OnInit {

  constructor(
    private visitorApi: VisitorsApiService,
    private dialog: MatDialog,
    private router: Router,
  ) { }

  form = new FormGroup({
    visitedStaffId: new FormControl(0, Validators.required),
    reasonId: new FormControl(0, Validators.required),
    visit: new FormControl(undefined as Date, Validators.required),
    file: new FormControl(null as string, Validators.required),
    type: new FormControl(null as string, Validators.required),
  });


  ngOnInit(): void {
  }

  onSubmit() {
    if (!this.form.valid) {
      this.dialog.open(AlertComponent, {
        width: '70%',
        data: { message: "Le formulaire n'est pas complet" },
      });
      return;
    }
    this.visitorApi.importVisitors(this.form.value)
      .then(res => {
        this.dialog.open(AlertComponent, {
          width: '70%',
          data: { message: "Les visiteurs ont été enregistrés" }
        }).afterClosed().subscribe({
          next: () => this.router.navigate(['/']),
        })
      })
      .catch(err => {
        this.dialog.open(AlertComponent, {
          width: '70%',
          data: { message: err.error.detail },
        })
      });
  }

  getFile(event) {
    const file = event.target.files[0] as File;
    this.convertFile(file).then(str => {
      this.form.patchValue({ file: str, type: file.type });
    })
  }

  private convertFile(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const fileReader: FileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        const fileReaderResultToString: string = <string>fileReader.result;
        resolve(fileReaderResultToString.split(',')[1]);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

}
