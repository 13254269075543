<h1 mat-dialog-title>Saisie</h1>
<div mat-dialog-content>
    <p>{{data.question}}</p>
    <mat-form-field>
        <mat-label>{{data.field}}</mat-label>
        <input matInput [(ngModel)]="answer" autocomplete="off">
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="answer" cdkFocusInitial>Valider</button>
    <button mat-button (click)="onNoClick()">Annuler</button>
</div>