<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<mat-card>
  <mat-card-title>Connexion</mat-card-title>
  <mat-card-subtitle>Bienvenue sur RegElec ! Veuillez vous authentifier pour continuer.</mat-card-subtitle>
  <mat-error *ngIf="errorMessage" class="errorMessage">
    {{ errorMessage }}
  </mat-error>
  <mat-card-content>
    <form [formGroup]="credentialForm" (submit)="login()">
      <mat-form-field>
        <mat-label>Email</mat-label>
        <input type="email" matInput required formControlName="email">
        <mat-error>
          Veuillez entrer une adresse email valide.
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Mot de passe</mat-label>
        <input matInput type="password" required formControlName="password">
        <mat-error>
          Veuillez saisir votre mot de passe.
        </mat-error>
      </mat-form-field>
      <button mat-raised-button color="primary" type="submit" [disabled]="credentialForm.invalid">Connexion</button>
      <a (click)="askForReset()">Mot de passe oublié ?</a>
    </form>
  </mat-card-content>
</mat-card>