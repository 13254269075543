import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatChipsModule } from "@angular/material/chips";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatTabsModule } from "@angular/material/tabs";
import { EmergencyMailsComponent } from "./emergency-mails/emergency-mails.component";
import { ParametersComponent } from "./parameters/parameters.component";
import { ReasonsComponent } from "./reasons/reasons.component";
import { VariablesComponent } from "./variables/variables.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ReasonCreationDialogComponent } from './reasons/reason-creation-dialog/reason-creation-dialog.component';
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatListModule } from "@angular/material/list";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSelectModule } from "@angular/material/select";
import { MatCardModule } from "@angular/material/card";
import { ActionDialogModule } from "../shared/action-dialog/action-dialog.module";
import { ReasonFormComponent } from './reasons/shared/reason-form/reason-form.component';

@NgModule({
  declarations: [
    ParametersComponent,
    EmergencyMailsComponent,
    VariablesComponent,
    ReasonsComponent,
    ReasonCreationDialogComponent,
    ReasonFormComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatInputModule,
    MatIconModule,
    MatTabsModule,
    MatDialogModule,
    MatButtonModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatListModule,
    MatPaginatorModule,
    MatSelectModule,
    MatCardModule,
    ActionDialogModule
  ],
})
export class ParamsModule {}
