<h3>Configuration</h3>

<mat-tab-group>
  <mat-tab label="Motifs de visite">
    <div class="padding">
      <app-reasons></app-reasons>
    </div>
  </mat-tab>
  <mat-tab label="Emails d'urgence">
    <div class="padding">
      <app-emergency-mails></app-emergency-mails>
    </div>
  </mat-tab>
  <mat-tab label="Variables">
    <div class="padding">
      <app-variables></app-variables>
    </div>
  </mat-tab>
</mat-tab-group>