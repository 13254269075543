import { HttpClient } from "@angular/common/http";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { environment } from "./../environments/environment";
import { AlertComponent } from "./alert/alert.component";
import { AuthService, Role } from "./auth.service";
import { ConfirmModalComponent } from "./confirm-modal/confirm-modal.component";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit, OnDestroy {
  API_URL: string;
  isLogged: boolean = false;
  role: Role = Role.USER;
  username: string;
  avatar: string;

  constructor(
    private client: HttpClient,
    private dialog: MatDialog,
    private auth: AuthService,
    private router: Router
  ) {
    this.API_URL = environment.api;
  }

  ngOnInit() {
    this.auth.logged.subscribe((logged) => {
      this.isLogged = logged;
      this.username = this.auth.username;
    });
    this.auth.role.subscribe((role) => (this.role = role));
    this.auth.profilePicture.subscribe((avatar) => (this.avatar = avatar));
  }

  ngOnDestroy() {}

  async sendAlert() {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: "70%",
      data: { question: `Êtes-vous sûr de vouloir signaler une urgence ?` },
    });

    await dialogRef
      .afterClosed()
      .toPromise()
      .then(async (result) => {
        if (result == true) {
          await this.client
            .get(`${this.API_URL}alert`)
            .toPromise()
            .then(() => {});
          this.showAlert("L'urgence a bien été signalée !");
        }
      });
  }

  async showAlert(msg: string) {
    const dialogRef = this.dialog.open(AlertComponent, {
      width: "70%",
      data: { message: msg },
    });

    await dialogRef
      .afterClosed()
      .toPromise()
      .then(async (result) => {});
  }

  logout() {
    this.auth.logout();
    this.router.navigate(["login"], { queryParams: { action: "logout" } });
  }
}
