<h3>Liste des visiteurs enregistrés sur site</h3>
<br>
<mat-progress-bar [color]="getColor(fillingrate)" [mode]="fillingrate ? 'determinate' : 'undeterminate'" [value]="fillingrate"></mat-progress-bar>
<br>
<mdb-card>
    <div class="mycontainer">
        <table mat-table [dataSource]="visitorsList">
            <ng-container matColumnDef="state">
                <th mat-header-cell *matHeaderCellDef> Statut </th>
                <td mat-cell *matCellDef="let visitor">
                    <div *ngIf="isAnormal(visitor.entry); else fine">
                        <mat-icon [matTooltip]="getTimeTooltip()" color="warn">error</mat-icon>
                    </div>
                    <ng-template #fine>
                        <mat-icon class="green-icon">check_circle</mat-icon>
                    </ng-template>
                </td>
            </ng-container>
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Visiteur </th>
                <td mat-cell *matCellDef="let visitor"> {{visitor.name}} </td>
            </ng-container>
            <ng-container matColumnDef="company">
                <th mat-header-cell *matHeaderCellDef> Société </th>
                <td mat-cell *matCellDef="let visitor"> {{visitor.company.name}} </td>
            </ng-container>
            <ng-container matColumnDef="visited_staff">
                <th mat-header-cell *matHeaderCellDef> Personne visitée </th>
                <td mat-cell *matCellDef="let visitor"> {{visitor.visitedStaff?.name ? visitor.visitedStaff.name : 'Utilisateur inconnu'}} </td>
            </ng-container>
            <ng-container matColumnDef="reason">
                <th mat-header-cell *matHeaderCellDef> Motif </th>
                <td mat-cell *matCellDef="let visitor"> {{visitor.reason?.label ? visitor.reason?.label : 'Non défini'}} </td>
            </ng-container>
            <ng-container matColumnDef="entry">
                <th mat-header-cell *matHeaderCellDef> Heure d'arrivée </th>
                <td mat-cell *matCellDef="let visitor"> {{visitor.entry.toLocaleTimeString()}} </td>
            </ng-container>
            <ng-container matColumnDef="comment">
                <th mat-header-cell *matHeaderCellDef> Commentaire </th>
                <td mat-cell *matCellDef="let visitor"
                    [ngStyle]="{'white-space': 'nowrap',overflow: 'hidden', 'text-overflow': 'ellipsis','max-width': '200px'}">
                    {{visitor.comment || 'Aucun'}}
                </td>
            </ng-container>
            <ng-container matColumnDef="exit">
                <th mat-header-cell *matHeaderCellDef> Sortie </th>
                <td mat-cell *matCellDef="let visitor"><button mat-icon-button matTooltip="Sortir" color="primary" tooltup (click)="confirmPIN(visitor.id)">
                        <mat-icon>follow_the_signs</mat-icon>
                    </button></td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;">
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">Aucun visiteur sur site</td>
            </tr>
        </table>
    </div>
</mdb-card>