<div class="searchContainer">
  <mat-form-field>
    <mat-label>Rechercher un motif de visite</mat-label>
    <input matInput placeholder="Rechercher" (input)="onPatternChange($event)" #patternInput>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Tri</mat-label>
    <mat-select (valueChange)="onSortCriteriaChange($event)" [ngModel]="params.sorter">
      <mat-option value="id">Ancienneté décroissante</mat-option>
      <mat-option value="label">Ordre alphabétique</mat-option>
    </mat-select>
  </mat-form-field>
  <button mat-mini-fab matTooltip="Nouveau" color="primary" type="button" (click)="openDialog()">
    <mat-icon>add</mat-icon>
  </button>
</div>

<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>

<mat-list *ngIf="items.length > 0 && !loading">
  <mat-list-item *ngFor="let reason of items">
    <div class="buttons">
      <button mat-icon-button type="button" (click)="delete(reason)">
        <mat-icon>delete</mat-icon>
      </button>
      <button mat-icon-button type="button" (click)="update(reason)">
        <mat-icon>edit</mat-icon>
      </button>
    </div>
    {{ reason.label }} <small> #{{reason.id}}</small>
  </mat-list-item>
</mat-list>

<p *ngIf="items.length == 0">Aucun motif trouvé.</p>

<mat-paginator [length]="total" [pageSize]="params.pagination.pageSize" [pageSizeOptions]="[25, 50, 100]"
  (page)="onPaginationChange($event)">
</mat-paginator>