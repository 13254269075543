import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatTable } from "@angular/material/table";
import { ActivatedRoute } from "@angular/router";
import { AlertComponent } from "../alert/alert.component";
import { Visitor } from "../models/visitors/visitor.model";
import { VisitorsApiService } from "../models/visitors/visitors-api.model";
import { StaticticsService } from "../stats/statictics.service";
import { TextModalComponent } from "../text-modal/text-modal.component";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  @ViewChild(MatTable) table: MatTable<Visitor>;
  visitorsList: Visitor[] = new Array();
  displayedColumns = [
    "state",
    "name",
    "company",
    "visited_staff",
    "reason",
    "entry",
    "comment",
    "exit",
  ];
  fillingrate;

  constructor(
    private dialog: MatDialog,
    private visitorsApi: VisitorsApiService,
    private route: ActivatedRoute,
    private statisticsAPI: StaticticsService
  ) {}

  async ngOnInit() {
    this.visitorsList.splice(0, this.visitorsList.length);
    (await this.visitorsApi.getvisitorsInside()).forEach((element) => {
      element.entry = new Date(element.entry);
      this.visitorsList.push(element);
    });
    this.statisticsAPI.getFillingRate().subscribe((result) => {
      this.fillingrate = result * 100;
    });
    this.table.renderRows();
  }

  async confirmPIN(id: number) {
    const dialogRef = this.dialog.open(TextModalComponent, {
      width: "70%",
      data: { question: `Saisissez le PIN`, field: "PIN" },
    });

    await dialogRef
      .afterClosed()
      .toPromise()
      .then(async (result) => {
        if (!result) {
          this.showAlert("Une erreur s'est produite !");
          return;
        }
        this.visitorsApi.exitVisitor(id, result).then((result) => {
          if (result) this.successExit();
          else this.wrongPIN();
        });
      });
  }

  async wrongPIN() {
    this.showAlert("Erreur: PIN incorrect !");
  }

  async successExit() {
    await this.showAlert("L'utilisateur a bien été enregistré comme sorti !");
    this.ngOnInit();
  }

  async showAlert(msg: string) {
    const dialogRef = this.dialog.open(AlertComponent, {
      width: "70%",
      data: { message: msg },
    });

    await dialogRef
      .afterClosed()
      .toPromise()
      .then(async (result) => {});
  }

  isAnormal(date: Date) {
    const duration: number = Date.now() - date.getTime();
    return (
      duration >=
      this.convertHoursToMilliseconds(this.statisticsAPI.getAnormalDuration())
    );
  }

  convertHoursToMilliseconds(hours: number): number {
    return hours * 60 * 60 * 1000;
  }

  ngOnDestroy() {}

  getColor(rate: number) {
    if (rate >= 90) return "warn";
    if (rate >= 75) return "accent";
    return "primary";
  }

  getTimeTooltip() {
    return (
      "Personne enregistrée depuis plus de " +
      this.statisticsAPI.getAnormalDuration() +
      " heures"
    );
  }
}
