import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { Visitor } from "src/app/models/visitors/visitor.model";
import { VisitorsApiService } from "src/app/models/visitors/visitors-api.model";

@Component({
  selector: "app-logs",
  templateUrl: "./logs.component.html",
  styleUrls: ["./logs.component.css"],
})
export class LogsComponent implements OnInit {
  @ViewChild(MatTable) table: MatTable<Visitor>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  visitorsList: Visitor[] = new Array();
  dataSource: MatTableDataSource<Visitor> = new MatTableDataSource(
    this.visitorsList
  );
  displayedColumns = [
    "name",
    "company",
    "visited_staff",
    "reason",
    "entry",
    "exit",
    "date",
  ];

  constructor(private visitorsApi: VisitorsApiService) {}

  async ngOnInit(): Promise<void> {
    this.visitorsList.splice(0, this.visitorsList.length);
    (await this.visitorsApi.getvisitorsLogs()).forEach((element) => {
      element.entry = new Date(element.entry);
      element.exitTime = new Date(element.exitTime);
      this.visitorsList.push(element);
    });
    this.dataSource = new MatTableDataSource(this.visitorsList);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.table.renderRows();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    this.dataSource.filterPredicate = (data, filter: string) => {
      const accumulator = (currentTerm, key) => {
        return (
          currentTerm +
          data.name +
          data.phone +
          data.mail +
          data.company.name +
          data.reason.label +
          data.visitedStaff.name +
          data.visitedStaff.mail +
          data.company.address +
          data.company.phone +
          data.entry.toLocaleDateString() +
          data.entry.toLocaleTimeString +
          data.exitTime.toLocaleDateString() +
          data.exitTime.toLocaleTimeString()
        );
      };
      const dataStr = Object.keys(data).reduce(accumulator, "").toLowerCase();
      const transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) !== -1;
    };

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
