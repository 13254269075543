import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-company-modal',
  templateUrl: './company-modal.component.html',
  styleUrls: ['./company-modal.component.css']
})
export class CompanyModalComponent implements OnInit {

  public answer: { name: string, address: string, phone: number };

  constructor(
    public dialogRef: MatDialogRef<CompanyModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.answer = { name: '', address: '', phone: 0 };
  }

  ngOnInit(): void { }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
