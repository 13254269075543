import {
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { tap } from "rxjs/operators";
import { ReasonsApiService } from "src/app/models/reason/reasons-api.model";

@Component({
  selector: "app-reason-form",
  templateUrl: "./reason-form.component.html",
  styleUrls: ["./reason-form.component.css"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ReasonFormComponent),
    },
    {
      provide: NG_VALIDATORS,
      useExisting: ReasonFormComponent,
      multi: true,
    },
  ],
})
export class ReasonFormComponent
  implements OnInit, ControlValueAccessor, OnChanges
{
  loading = false;
  form = new FormControl(null, [Validators.required]);
  @Input() conflictError: false;

  constructor(private service: ReasonsApiService) {}
  ngOnChanges(changes: SimpleChanges): void {
    const error = changes["conflictError"].currentValue;
    this.form.setErrors(error);
  }

  ngOnInit(): void {}

  writeValue(obj: string): void {
    this.form.setValue(obj);
  }
  registerOnChange(fn: (label) => void): void {
    if (fn) this.form.valueChanges.subscribe((label) => fn(label));
  }

  registerOnTouched(fn: (label) => void): void {
    this.form.valueChanges.pipe(tap(() => (this.loading = true)));
  }
  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.form.errors;
  }
}
