<h3>Enregistrer une visite</h3>

<form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
  <mat-form-field>
    <mat-label>Nom</mat-label>
    <input type="text" formControlName="name" matInput autocomplete="off">
    <mat-error *ngIf="registerForm.get('name').hasError('required')">
      Un nom est <strong>obligatoire</strong>
    </mat-error>
  </mat-form-field>
  <div class="input-with-fab">
    <app-selection-input [label]="'Société'" [endpoint]="'companies'" [showImage]="false"
      formControlName="company"></app-selection-input>
      <button mat-mini-fab type="button" color="primary" (click)="openCompanyDialog()">
        <mat-icon>add</mat-icon>
      </button>
  </div>

  <app-selection-input [label]="'Personne visitée'" [endpoint]="'people'" [showImage]="true"
    formControlName="visited_staff"></app-selection-input>
  <app-selection-input [label]="'Motif de visite'" [endpoint]="'reasons'" [showImage]="false"
    formControlName="reason"></app-selection-input>
  <mat-form-field>
    <mat-label>Téléphone</mat-label>
    <input type="text" formControlName="phone" matInput autocomplete="off">
    <mat-error *ngIf="registerForm.get('phone').hasError('pattern') && !registerForm.get('phone').hasError('required')">
      Veuillez entrer un numéro valide
    </mat-error>
    <mat-error *ngIf="registerForm.get('phone').hasError('required')">
      Un numéro est <strong>obligatoire</strong>
    </mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Mail</mat-label>
    <input type="email" formControlName="mail" matInput autocomplete="off">
    <mat-error *ngIf="registerForm.get('mail').hasError('email') && !registerForm.hasError('required')">
      Veuillez entrer un mail valide
    </mat-error>
    <mat-error *ngIf="registerForm.get('mail').hasError('required')">
      Un mail est <strong>obligatoire</strong>
    </mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Commentaire</mat-label>
    <textarea formControlName="comment" matInput autocomplete="off"></textarea>
  </mat-form-field>
  <br />
  <br />
  <button mat-raised-button color="primary" type="submit" [disabled]="registerForm.invalid">Enregistrer</button>
</form>