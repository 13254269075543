import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-people-modal',
  templateUrl: './people-modal.component.html',
  styleUrls: ['./people-modal.component.css']
})
export class PeopleModalComponent implements OnInit {

  public answer: { name: string, mail: string };

  constructor(
    public dialogRef: MatDialogRef<PeopleModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.answer = { name: '', mail: '' };
  }

  ngOnInit(): void { }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
