import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import "rxjs/add/operator/catch";
import { environment } from "src/environments/environment";
import { Company } from "./company.model";

@Injectable()
export class CompaniesApiService {
  API_URL: string;

  constructor(private http: HttpClient) {
    this.API_URL = environment.api;
  }

  private static _handleError(err: HttpErrorResponse | any) {
    return Observable.throw(
      err.message || "Error: Unable to complete request."
    );
  }

  async getCompanies(): Promise<Company[]> {
    return this.http.get<Company[]>(`${this.API_URL}companies`).toPromise();
  }

  async getCompany(id: number): Promise<Company> {
    return await this.http
      .get<Company>(`${this.API_URL}companies/${id}`)
      .toPromise();
  }

  async addCompany(company: Company): Promise<Company> {
    return await this.http
      .post<Company>(`${this.API_URL}companies/`, company)
      .toPromise();
  }
}
