import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { BehaviorSubject } from "rxjs";
import { debounceTime, switchMap, tap } from "rxjs/operators";
import { Reason } from "src/app/models/reason/reason.model";
import { ReasonsApiService } from "src/app/models/reason/reasons-api.model";
import {
  FilterOperator,
  MatchMode,
  OrderMode,
  Pagination,
  SearchParams,
} from "src/app/models/search/search.model";
import { ActionDialogComponent } from "src/app/shared/action-dialog/action-dialog.component";
import { ReasonCreationDialogComponent as ReasonCreationDialog } from "./reason-creation-dialog/reason-creation-dialog.component";

@Component({
  selector: "app-reasons",
  templateUrl: "./reasons.component.html",
  styleUrls: ["./reasons.component.css"],
})
export class ReasonsComponent implements OnInit {
  loading: boolean = false;
  params: {
    pattern: string;
    sorter: "id" | "label";
    pagination: Pagination;
  } = {
    pattern: null,
    sorter: "label",
    pagination: { pageNumber: 0, pageSize: 25 },
  };
  @ViewChild("patternInput") patternInput: ElementRef;
  patternSubject: BehaviorSubject<{ pattern; sorter; pagination }> =
    new BehaviorSubject(this.params);
  items: Reason[] = [];
  total: number = 0;

  constructor(
    private service: ReasonsApiService,
    public dialog: MatDialog,
    private snackbar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.onSearchParamChange();
    this.patternSubject
      .pipe(
        tap(() => (this.loading = true)),
        debounceTime(200),
        switchMap((query) => {
          return this.onSearchParamChange();
        })
      )
      .subscribe({
        next: (res) => {
          this.loading = false;
          this.items = res.items;
          this.total = res.total;
        },
        error: (err) => {
          this.loading = false;
          console.error(err);
        },
      });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ReasonCreationDialog, { width: "50vw" });
    dialogRef.afterClosed().subscribe((result: Reason) => {
      if (result) {
        this.items.unshift(result);
        this.snackbar.open(
          `Le motif de visite "${result.label}" a bien été créé.`,
          "D'accord",
          { duration: 3000 }
        );
      }
    });
  }

  onPatternChange(event) {
    this.params.pattern = this.patternInput.nativeElement.value;
    this.patternSubject.next(this.params);
  }
  onSortCriteriaChange(criteria: "id" | "label") {
    this.params.sorter = criteria;
    this.patternSubject.next(this.params);
  }
  onPaginationChange(pagination) {
    this.params.pagination.pageNumber = pagination.pageIndex;
    this.params.pagination.pageSize = pagination.pageSize;
    this.patternSubject.next(this.params);
  }

  onSearchParamChange() {
    const params: SearchParams = {
      filters: this.params.pattern
        ? [
            {
              column: "label",
              matchmode: MatchMode.ALL,
              rules: [
                {
                  filterOperator: FilterOperator.CONTAINS,
                  value: this.params.pattern,
                },
              ],
            },
          ]
        : null,
      sorters: [
        {
          column: this.params.sorter,
          mode: this.params.sorter == "id" ? OrderMode.DESC : OrderMode.ASC,
        },
      ],
      pagination: {
        pageNumber: this.params.pagination.pageNumber,
        pageSize: this.params.pagination.pageSize,
      },
    };
    return this.service.search(params);
  }

  delete(item) {
    const dialogRef = this.dialog.open(ActionDialogComponent, {
      data: {
        title: "Suppression d'un motif de visite",
        text: "Êtes-vous sûr de vouloir supprimer ce motif de visite ? Cette opération est définitive.",
        buttons: [
          {
            color: "primary",
            label: "Supprimer",
            method: () => {
              return true;
            },
          },
          {
            color: "secondary",
            label: "Annuler",
            method: () => {
              return false;
            },
          },
        ],
      },
    });
    dialogRef.afterClosed().subscribe((r) => {
      if (r) {
        this.loading = true;
        this.service.delete(item.id).subscribe({
          next: (r) => {
            this.loading = false;
            this.items.splice(this.items.indexOf(item), 1);
            --this.total;
            this.snackbar.open(
              `Le motif "${item.label}" a bien été supprimé.`,
              "D'accord",
              { duration: 3000 }
            );
          },
          error: (err) => {
            this.loading = false;
            this.snackbar.open(
              "Echec de la suppression. Veuillez réessayer ultérieurement.",
              "D'accord"
            );
          },
        });
      }
    });
  }

  update(item) {
    const dialogRef = this.dialog.open(ReasonCreationDialog, {
      width: "50vw",
      data: item,
    });
    dialogRef.afterClosed().subscribe((result: Reason) => {
      if (result) {
        const idx = this.items.findIndex(r => r.id == result.id)
        this.items[idx].label = result.label;
        this.snackbar.open(
          `Les modifications ont été enregistrées.`,
          "D'accord",
          { duration: 3000 }
        );
      }
    });
  }
}
