import { Component } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AuthService } from "src/app/auth.service";

@Component({
  selector: "app-password-reset-dialog",
  templateUrl: "./password-reset-dialog.component.html",
  styleUrls: ["./password-reset-dialog.component.css"],
})
export class PasswordResetDialogComponent {
  email = new FormControl(null, [Validators.required, Validators.email]);
  loading: boolean = false;
  errorMessage: string;

  constructor(
    public dialogRef: MatDialogRef<PasswordResetDialogComponent>,
    private auth: AuthService
  ) {}

  cancel() {
    this.dialogRef.close(false);
  }

  submit() {
    this.loading = true;
    this.auth.sendResetLink(this.email.value).subscribe({
      next: () => {
        this.loading = false;
        this.dialogRef.close(true);
      },
      error: (err) => {
        this.loading = false;
        this.errorMessage = "Une erreur est survenue, veuillez réessayer ultérieurement."
      }
    })
  }
}
