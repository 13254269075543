<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<mat-card>
  <mat-card-title>Réinitialisation du mot de passe</mat-card-title>
  <mat-card-subtitle>
    Pour réinitialiser votre mot de passe, veuillez compléter le formulaire suivant.
  </mat-card-subtitle>
  <mat-error *ngIf="errorMessage" class="errorMessage">
    {{ errorMessage }}
  </mat-error>
  <mat-card-content>
    <form [formGroup]="passwordsForm" (submit)="submit()">
      <mat-form-field>
        <mat-label>Nouveau mot de passe</mat-label>
        <input type="password" matInput required formControlName="password" autocomplete="off">
        <mat-error>
          Veuillez saisir votre nouveau mot de passe.
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Confirmation</mat-label>
        <input matInput type="password" required formControlName="confirmation" autocomplete="off">
        <mat-error>
          Veuillez confirmer votre mot de passe.
        </mat-error>
      </mat-form-field>
      <mat-error *ngIf="passwordsForm.hasError('passwordsNoMatch') && passwordsForm.controls['confirmation'].touched" style="font-size: 75%; font-weight: 400;">
        Les mots de passe ne correspondent pas.
      </mat-error>
      <button mat-raised-button color="primary" type="submit" [disabled]="passwordsForm.invalid">Confirmer</button>
    </form>
  </mat-card-content>
</mat-card>