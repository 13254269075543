import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class StaticticsService {
  API_URL: string;
  private anormalDuration: number;

  getAverageVisitors(): Observable<number> {
    return this.http.get<number>(`${this.API_URL}statistics/averagevisitors`);
  }

  getNumberVisitors(): Observable<number> {
    return this.http.get<number>(`${this.API_URL}statistics/numbervisitors`);
  }

  getAverageTime(): Observable<number> {
    return this.http.get<number>(`${this.API_URL}statistics/averagetime`);
  }

  getPlanificationRate(): Observable<number> {
    return this.http.get<number>(`${this.API_URL}statistics/planificationrate`);
  }

  getFillingRate() {
    return this.http.get<number>(`${this.API_URL}statistics/fillingrate`);
  }

  getAnormalDuration(): number {
    return this.anormalDuration;
  }

  constructor(private http: HttpClient) {
    this.API_URL = environment.api;
    this.http
      .get<number>(`${this.API_URL}statistics/anormalduration`)
      .subscribe((anormalDuration) => (this.anormalDuration = anormalDuration));
  }
}
