import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../auth.service";
import { PasswordResetDialogComponent } from "./password-reset-dialog/password-reset-dialog.component";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  credentialForm = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email]),
    password: new FormControl(null, Validators.required),
  });

  loading: boolean = false;
  errorMessage: string;

  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params) => {
      const action = params.get("action");
      let message: string;
      switch (action) {
        case "resetPassword":
          message = "Votre mot de passe a été réinitialisé avec succès.";
          break;
        case "logout":
          message = "Vous avez été déconnecté.";
          break;
        case "expiredToken":
          message = "Votre session a expiré. Veuillez vous reconnecter.";
          break;
      }
      if (message) {
        this._snackBar.open(message, "D'accord", {duration: 3000});
      }
    });
  }

  login() {
    this.loading = true;
    this.auth.login(this.credentialForm.value).subscribe({
      next: () => {
        this.router.navigate([""]);
        this.loading = false;
      },
      error: (err) => {
        if (err.status == 401) {
          this.errorMessage = "Identifiants incorrects.";
        } else {
          this.errorMessage =
            "Une erreur est survenue, veuillez réessayer ultérieurement.";
        }
        this.loading = false;
      },
    });
  }

  askForReset() {
    const dialogRef = this.dialog.open(PasswordResetDialogComponent);
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._snackBar.open(
          "Un lien de réinitialisation a été envoyé. Pensez à consulter vos spams.",
          "D'accord"
        );
      }
    });
  }
}
