<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
<form [formGroup]="form" (submit)="submit()">
  <mat-form-field>
    <mat-label>Identifiant unique</mat-label>
    <input type="text" name="id" id="id" disabled matInput [value]="userId" autocomplete="off">
  </mat-form-field>
  <app-people-form #userForm formControlName="user" [parentErrorField]="errorField"></app-people-form>
  <div class="buttons">
    <button type="button" mat-raised-button *ngIf="!editing" (click)="edit()">Editer</button>
    <button type="button" mat-raised-button *ngIf="editing" (click)="cancel()">Annuler</button>
    <button type="submit" mat-raised-button color="primary" *ngIf="editing" [disabled]="form.invalid">Enregister</button>
  </div>
</form>