import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertComponent } from 'src/app/alert/alert.component';
import { ParamsService } from '../params.service';

@Component({
  selector: 'app-variables',
  templateUrl: './variables.component.html',
  styleUrls: ['./variables.component.css']
})
export class VariablesComponent implements OnInit, OnDestroy {
  settingsForm: FormGroup;
  dialog: any;

  //oldValues
  oldMaxVisitors: number;
  oldAnormalDuration: number;

  constructor(private fb: FormBuilder, private paramsAPI: ParamsService) {
    this.settingsForm = this.fb.group({
      maxVisitors: ['', Validators.required],
      anormalDuration: ['', Validators.required],
    });
  }

  async ngOnDestroy(): Promise<void> {
    const anormalDuration = this.settingsForm.get('anormalDuration').value
    const maxVisitors = this.settingsForm.get('maxVisitors').value
    if (this.settingsForm.invalid) {
      this.showAlert("Les valeurs saisies sont incorrectes ! Modifications ignorées.")
    }
    if (anormalDuration != this.oldAnormalDuration) {
      await this.paramsAPI.setAnormalDuration(this.settingsForm.get('anormalDuration').value);
    }
    if (maxVisitors != this.oldMaxVisitors) {
      await this.paramsAPI.setMaxVisitors(this.settingsForm.get('maxVisitors').value)
    }
  }

  ngOnInit(): void {
    this.paramsAPI.getAnormalDuration().then(result => {
      this.oldAnormalDuration = result;
      this.settingsForm.get('anormalDuration').setValue(this.oldAnormalDuration);
    })
    this.paramsAPI.getMaxVisitors().then(result => {
      this.oldMaxVisitors = result;
      this.settingsForm.get('maxVisitors').setValue(this.oldMaxVisitors);
    })
  }

  async showAlert(msg: string) {
    const dialogRef = this.dialog.open(AlertComponent, {
      width: '70%',
      data: { message: msg }
    });

    await dialogRef.afterClosed().toPromise().then(async result => {
    });
  }

}
