import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { jwtDecode } from "jwt-decode";
import { BehaviorSubject } from "rxjs";
import { Observable } from "rxjs-compat";
import { tap } from "rxjs/operators";
import { environment } from "src/environments/environment";

export type Credential = { email: string; password: string };
export enum Role {
  ADMIN,
  USER,
}

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private API_ENDPOINT: string;

  public logged: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public role: BehaviorSubject<Role> = new BehaviorSubject(null);
  public profilePicture: BehaviorSubject<string> = new BehaviorSubject(null);

  constructor(private http: HttpClient) {
    this.API_ENDPOINT = environment.api;
    this.checkIfAnUserIsAlreadyLoggedIn();
  }

  /**
   * Vérifier qu'un utilisateur est déjà connecté.
   * La fonction récupère un éventuel jeton de connexion issu du stockage local
   * et vérifie sa validité.
   */
  private checkIfAnUserIsAlreadyLoggedIn() {
    const token: string = localStorage.getItem("token");
    if (token && !this.isTokenExpired(token)) {
      this.logged.next(true);
      const role = this.getRole(token);
      this.role.next(role);
      this.fetchProfilePicture(this.getId(token));
    } else this.logged.next(false);
  }

  /**
   * Authentifier le visiteur sur l'application.
   * @param credential Les informations de connexion (email, mot de passe).
   * @returns Un Observable contenant le jeton de connexion.
   */
  login(credential: Credential): Observable<string> {
    return this.http.post<string>(`${environment.api}auth`, credential).pipe(
      tap((token) => {
        localStorage.setItem("token", token);
        this.logged.next(true);
        this.role.next(this.getRole(token));
        this.fetchProfilePicture(this.getId(token));
      })
    );
  }

  /**
   * Demander la réception d'un lien de réinitialisation de mot de passe par email.
   * @param email L'adresse à laquelle recevoir le lien.
   * @returns Un Observable de succès.
   */
  sendResetLink(email: string) {
    return this.http.post(`${this.API_ENDPOINT}reset`, {
      value: email,
    });
  }

  /**
   * Réinitialiser le mot de passe.
   * @param resetId L'identifiant de la demande de réinitialisation.
   * @param password Le nouveau mot de passe.
   * @returns Un Observable de succès.
   */
  resetPassword(resetId: string, password: any) {
    return this.http.post(`${this.API_ENDPOINT}reset/${resetId}`, {
      value: password,
    });
  }

  /**
   * Déconnecter l'utilisateur. La fonction supprime le jeton
   * stocké par l'application.
   */
  logout() {
    localStorage.removeItem("token");
    this.logged.next(false);
    this.role.next(null);
  }

  /**
   * Vérifier la validité du jeton de connexion.
   * Si le timestamp d'expiration est atteint, le jeton n'est plus valide.
   * @param token Le jeton de connexion.
   * @returns true s'il a expiré, false sinon.
   */
  private isTokenExpired(token: string): boolean {
    const { exp } = jwtDecode(token);
    return exp < Date.now() / 1000;
  }

  /**
   * Obtenir le nom d'utilisateur contenu dans le jeton de connexion.
   */
  public get username() {
    const token = localStorage.getItem("token");
    return token ? jwtDecode(token)["name"] : null;
  }

  public get id() {
    const token = localStorage.getItem("token");
    const decoded = jwtDecode(token);
    return token ? parseInt(decoded["userId"]) : null;
  }

  /**
   * Obtenir le rôle de l'utilisateur contenu dans le jeton de connexion.
   * @param token Le jeton de connexion.
   * @returns Le rôle (ADMINISTRATEUR ou UTILISATEUR).
   */
  public getRole(token: string): Role {
    const extracted = jwtDecode(token)["role"];
    return extracted == "ADMIN" ? Role.ADMIN : Role.USER;
  }

  /**
   * Obtenir le rôle de l'utilisateur contenu dans le jeton de connexion.
   * @param token Le jeton de connexion.
   * @returns Le rôle (ADMINISTRATEUR ou UTILISATEUR).
   */
  public getId(token: string): Role {
    const extracted = jwtDecode(token)["userId"];
    return extracted;
  }

  private fetchProfilePicture(userId: number) {
    this.http.get(`${this.API_ENDPOINT}people/${userId}/avatar`).subscribe({
      next: (v: any) => {
        this.profilePicture.next(v.value);
      },
      error: (err) => {},
    });
  }
}
