import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { PeopleInputDto } from "src/app/models/peoples/people.model";
import { PeoplesApiService } from "src/app/models/peoples/peoples-api.model";

@Component({
  selector: "app-peoplecreation",
  templateUrl: "./creation.component.html",
  styleUrls: ["./creation.component.css"],
})
export class PeopleCreationComponent implements OnInit {
  form = new FormGroup({
    user: new FormControl(null),
  });

  loading = false;
  errorField = null;

  constructor(
    private router: Router,
    private service: PeoplesApiService,
    private snackbar: MatSnackBar
  ) {}

  ngOnInit(): void {}

  submit() {
    this.loading = true;
    const values = this.form.value.user;
    const data: PeopleInputDto = {
      mail: values.mail,
      id: 0,
      name: values.name,
      role: values.role,
      substituteId: values.substituteId,
    };
    this.service.add(data).subscribe({
      next: (created) => {
        this.loading = false;
        this.snackbar.open("L'utilisateur a bien été créé.", "D'accord", {
          duration: 3000,
        });
        this.router.navigate(["users"]);
      },
      error: (err) => {
        this.loading = false;
        if (err.status == 409) {
          this.errorField = "mail";
        }
        this.snackbar.open(
          "Une erreur est survenue, vuillez réessayer ultérieurement.",
          "D'accord",
          {
            duration: 3000,
          }
        );
        console.error(err);
      },
    });
  }
}
