<form [formGroup]="form" (submit)="submit()">
  <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
  <h1 mat-dialog-title>{{ title }}</h1>
  <div mat-dialog-content>
      <app-reason-form formControlName="reason" [conflictError]="conflictError"></app-reason-form>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="cancel()" type="button">Annuler</button>
    <button mat-raised-button color="primary" type="submit" [disabled]="form.invalid">Enregistrer</button>
  </div>
</form>