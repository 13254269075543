<h3>Importer des visites</h3>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <app-selection-input [label]="'Personne visitée'" [endpoint]="'people'" [showImage]="true"
    formControlName="visitedStaffId"></app-selection-input>
  <app-selection-input [label]="'Motif de visite'" [endpoint]="'reasons'" [showImage]="false"
    formControlName="reasonId"></app-selection-input>
  <mat-form-field>
    <mat-label>Date de visite</mat-label>
    <input type="datetime-local" formControlName="visit" matInput autocomplete="off">
    <mat-error *ngIf="form.get('visit').hasError('required')">
      Une date de visite est <strong>obligatoire</strong>
    </mat-error>
  </mat-form-field>
  <br />
  <input type="file" [multiple]="false"
    accept="text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    (input)="getFile($event)">
  <br />
  <br />
  <button mat-raised-button color="primary" type="submit">Enregistrer</button>
</form>