import {
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  Validators,
} from "@angular/forms";
import { of, Subject } from "rxjs";
import { catchError, debounceTime, switchMap, tap } from "rxjs/operators";
import { SelectionInputService } from "./selection-input.service";

export type SelectionItem<T> = {
  id: T;
  title: string;
  subtitle?: string;
  image?: string;
};

@Component({
  selector: "app-selection-input",
  templateUrl: "./selection-input.component.html",
  styleUrls: ["./selection-input.component.css"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SelectionInputComponent),
    }
  ],
})
export class SelectionInputComponent<T>
  implements OnInit, ControlValueAccessor
{
  @Input() label: string;
  @Input() endpoint: string;
  @Input() showImage: boolean = false;

  input = new FormControl(null, Validators.required);
  @ViewChild("inputRef") inputRef: ElementRef;

  options: SelectionItem<T>[] = [];

  querySubject: Subject<string> = new Subject();

  loading = false;

  onChange: (value: T) => void;
  constructor(private service: SelectionInputService) {}

  ngOnInit(): void {
    this.querySubject
      .pipe(
        tap(() => (this.loading = true)),
        debounceTime(200),
        switchMap((query) => {
          if (!query.length) return of([]);
          else
            return this.service
              .find<T>(this.endpoint, query)
              .pipe(catchError(() => of(null)));
        })
      )
      .subscribe({
        next: (response: SelectionItem<T>[]) => {
          this.loading = false;
          this.options = response;
        },
        error: (error) => console.error(error),
      });
  }

  find(e) {
    const value = (e.target as HTMLInputElement).value;
    if (value) this.querySubject.next(value);
    else this.clear();
  }

  clear() {
    this.options = [];
    this.input.setValue(null);
    this.onChange(null);
  }

  select(e) {
    const v = e.option.value;
    this.input.setValue(v.title);
    this.onChange(v.id);
  }

  writeValue(id: T): void {
    if (id) {
      this.service.findById<T>(this.endpoint, id).subscribe({
        next: (item) => {
          this.input.setValue(item.title);
        },
        error: (err) => {
          this.input.setValue(id);
          console.error(err);
        },
      });
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {}
  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.input.disable() : this.input.enable();
  }
}
